import { useCallback, useMemo } from 'react';

import { handleSuccess } from '../handlers';
import {
    MoveListsMutationVariables,
    useMoveListsMutation,
} from '../reactQueryHooks';
import { useQueryClient } from '@tanstack/react-query';
import { publish } from '@utils/window/pubsub';

import useGetCommonVariables from 'apis/useGetCommonVariables';

const useMoveLists = (options?: Parameters<typeof useMoveListsMutation>[0]) => {
    const queryClient = useQueryClient();
    const commonVariables = useGetCommonVariables();

    const {
        mutate,
        mutateAsync: _,
        ...rest
    } = useMoveListsMutation({
        ...options,
        onSuccess: (data, ...rest) => {
            // Send pubsub event when favourites lists are updated
            publish('FAVOURITE_CLIENT/UPDATE_DATA', {});
            options?.onSuccess?.(data, ...rest);
        },
    });

    const moveLists = useCallback(
        (
            variables: Pick<MoveListsMutationVariables, 'sourceUserId'>,
            mutateOptions?: Parameters<typeof mutate>[1]
        ) => {
            mutate(
                { ...variables, ...commonVariables },
                {
                    ...mutateOptions,
                    onSuccess: (data, ...rest) => {
                        handleSuccess(queryClient, data, commonVariables);
                        mutateOptions?.onSuccess?.(data, ...rest);
                    },
                }
            );
        },
        [commonVariables, mutate, queryClient]
    );

    return useMemo(() => ({ ...rest, moveLists }), [moveLists, rest]);
};

export default useMoveLists;
