import { SettingsObject } from '@hooks/useSettings';
import { getMarketData } from '@utils/getMarketData';
import { MarketName } from 'lib/global';

export const defaults = (market: MarketName): Partial<SettingsObject> => {
    const { country, language } = getMarketData(market);
    const baseUrl = 'https://www.ikea.com';
    const urlBaseName = 'favourites';
    const url = `${baseUrl}/${country}/${language}/${urlBaseName}/`;
    /*
     * Magic key 'from'
     *
     * If this occurs we need to replace it with
     * our website URL, but URIEncoded.
     * Because this information has not yet been
     * expanded, we generate it manually:
     */
    const from = encodeURIComponent(url);
    return {
        browseProductsUrl: `${baseUrl}/${country}/${language}/`,
        compareUrl: `${baseUrl}/${country}/${language}/compare/?pids=`,
        currency: 'EUR',
        ecoFeeParticipationUrl: '',
        enableAlternativeAvailabilityCheck: false,
        familyRewardKeysUrl: `${baseUrl}/${country}/${language}/ikea-family/benefits/rewards/`,
        familySignupUrl: `${baseUrl}/${country}/${language}/profile/signup/family/?itm_campaign=favourites&itm_element=cta&itm_content=signup&from=${from}`,
        familyUpgradeUrl: `${baseUrl}/${country}/${language}/profile/upgrade/?itm_campaign=favourites&itm_element=cta&itm_content=upgrade&from=${from}`,
        getStoresUrl: `${baseUrl}/${country}/${language}/meta-data/navigation/stores.json`,
        giftRegistryClientId: 'favourites',
        giftRegistryEndpoint: 'https://igift.ingka.com/graphql',
        giftRegistryManageUrl: `${baseUrl}/${country}/${language}/gift-registry/manage/edit/`,
        hideAddToCart: false,
        includeVAT: true,
        kompassEnpoint: 'https://metro.ikea.net',
        listClientId: 'fe88ae57-2c34-4b14-8979-b915872886f7',
        listEndpoint: 'https://favs.oneweb.ingka.com/graphql',
        loginUrl: `${baseUrl}/${country}/${language}/profile/login/?itm_campaign=favourites&itm_element=cta&itm_content=login&from=${from}`,
        maxItems: 999,
        maxListNameLength: 60,
        maxLists: 100,
        measurement: 'metric',
        optimizelyBase: 'https://www.ikea.com',
        optimizelyKey: 'HXzJzk89Uh8f9D8zHgMUx',
        pipUrl: `${baseUrl}/${country}/${language}/p/-`,
        profileClientId: '4d628482-8d07-4124-9010-3bad3fa622cd',
        profileEndpoint: 'https://api.ingka.ikea.com/api/profile/icm',
        publicUrl: `${baseUrl}/${country}/${language}/${urlBaseName}/`,
        shareUrl: `/${country}/${language}/${urlBaseName}/receive-share`,
        showClickAndCollect: true,
        showDevModal: false,
        showDisplayUnit: false,
        showEcoFee: false,
        signupUrl: `${baseUrl}/${country}/${language}/profile/signup/regular/?itm_campaign=favourites&itm_element=cta&itm_content=signup&from=${from}`,
        sunClientId: '7dbe0b5a-47d8-472e-9205-f79d478697ef',
        sunEnable: true,
        sunEndpoint: 'https://api.ingka.ikea.com/sun/signup',
        sunPrivacyPolicyLink: `${baseUrl}/${country}/${language}/customer-service/privacy-policy/`,
        urlBaseName: 'favourites',
        websiteBase: 'https://www.ikea.com',
    };
};
