import {
    createContext,
    PropsWithChildren,
    useCallback,
    useMemo,
    useRef,
    useState,
} from 'react';

import RootModal from './RootModal';
import {
    ModalContext as ModalContextType,
    Modals,
    ModalsState,
    OnBack,
    ShowModal,
} from './types';

export const ModalContext = createContext<ModalContextType>({
    onClose: () => undefined,
    showModal: () => undefined,
});

export const ModalProvider = ({ children }: PropsWithChildren) => {
    const [modalState, setModalState] = useState<ModalsState>({});
    const closeTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

    const showModal: ShowModal = useCallback((name, props) => {
        if (closeTimer.current) {
            clearTimeout(closeTimer.current); // Avoid closing modal if opened rapidly after close.
        }
        setModalState(state => ({
            ...state,
            [name]: {
                ...props,
                open: true,
            },
        }));
    }, []);

    const onBack: OnBack = useCallback(name => {
        setModalState(state => ({
            ...state,
            [name]: {
                ...state[name],
                open: false,
            },
        }));
    }, []);

    const onClose = useCallback(() => {
        setModalState(state =>
            Object.keys(state).reduce<ModalsState>(
                (acc, curr) => ({
                    ...acc,
                    [curr]: {
                        ...acc[curr as keyof Modals],
                        open: false,
                    },
                }),
                state
            )
        );
        // Wait for close transition to finish before unmounting components.
        closeTimer.current = setTimeout(() => {
            setModalState({});
        }, 500);
    }, []);

    const value = useMemo(
        () => ({
            showModal,
            onBack,
            onClose,
        }),
        [onBack, showModal, onClose]
    );

    return (
        <ModalContext.Provider value={value}>
            {children}
            {Object.keys(modalState).map(name => {
                const modalName = name as keyof Modals;
                return (
                    <RootModal
                        key={modalName}
                        name={modalName}
                        onBack={() => onBack(modalName)}
                        onClose={onClose}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        {...(modalState[modalName] as any)}
                    />
                );
            })}
        </ModalContext.Provider>
    );
};
