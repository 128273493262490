import React, { ReactNode } from 'react';

import useMoveFromGuestToLoggedInUser from '@hooks/useMoveFromGuestToLoggedInUser';

interface PageContainerProps {
    children: ReactNode;
}

const PageContainer = ({ children }: PageContainerProps) => {
    useMoveFromGuestToLoggedInUser();
    return <main className="list-ingka-content-container">{children}</main>;
};

export default PageContainer;
