import { defaults } from './defaults';
import { localhost } from './localhost';
import { prCte } from './pr-cte';
import { prPpe } from './pr-ppe';
import { ikeaEnv } from 'lib/global';

const defaultsByEnv = {
    'pr-ppe': prPpe,
    'pr-cte': prCte,
    localhost,
} as const;

const uses =
    ikeaEnv in defaultsByEnv
        ? {
              ...defaults,
              ...defaultsByEnv[ikeaEnv as keyof typeof defaultsByEnv],
          }
        : defaults;

export default uses;
