import { isIkeaEnv } from './typeguards';
import { IkeaEnv, MarketName } from './types';

export const ikeaEnvironments = [
    'cte',
    'localhost',
    'ppe',
    'pr-cte',
    'pr-ppe',
    'production',
] as const;

export const ikeaEnv: IkeaEnv = isIkeaEnv(process.env.NEXT_PUBLIC_IKEA_ENV)
    ? process.env.NEXT_PUBLIC_IKEA_ENV
    : 'localhost';

export const defaultMarketName: MarketName = 'usen';

/* Build information */
export const version: string = process.env.NEXT_PUBLIC_VERSION ?? 'dev';

/* Phrase keys */
export const phraseEndpoint = 'https://api.phrase.com/v2';
export const phraseProjectId = 'f4cf73b3dfb5cc4c924f62477309ff65';
const phraseAuthToken = process.env.PHRASE_TOKEN;

const phraseTeamSlackEmail =
    'dt-sp-trojan-pigs-fe-aaaackouqvtcckr2v4zx3ha5qq@ikea.org.slack.com';
export const phraseHeaders: RequestInit['headers'] = {
    Authorization: `token ${phraseAuthToken}`,
    'User-Agent': `IKEA Favourites BFF (${
        ikeaEnv === 'production' ? phraseTeamSlackEmail : ikeaEnv
    })`,
};

/* Selling range */
export const sellingRangeClientId = 'af1c03f0-de94-49c7-a690-8a41059c695b';
export const sellingRangeEndpoint = 'https://api.ingka.ikea.com/salesitem';

/* ESI logic */
const esiHosts: Record<IkeaEnv, string> = {
    'pr-cte': 'https://cte-fragments.cdn.ingka.com',
    'pr-ppe': 'https://fragments.cdn.ingka.com',
    cte: 'https://cte-fragments.cdn.ingka.com',
    localhost: 'https://www.ikea.com', // We can't use the Fastly host b/c of CORS?
    ppe: 'https://fragments.cdn.ingka.com',
    production: 'https://fragments.cdn.ingka.com',
};

export const esiBase = esiHosts[ikeaEnv];

export const shouldRenderEsi =
    !process.env.NEXT_PUBLIC_IKEA_ENV || process.env.RENDER_ESI === 'true';

export const shouldMockProd = ikeaEnv !== 'production';

export const isOptimizelyDisabled =
    process.env.NODE_ENV === 'development' &&
    process.env.NEXT_PUBLIC_DISABLE_OPTIMIZELY !== 'false';

export const isAnalyticsDisabled =
    process.env.NODE_ENV === 'development' &&
    process.env.NEXT_PUBLIC_DISABLE_ANALYTICS !== 'false';

export const isSentryDisabled =
    process.env.NODE_ENV === 'development' &&
    process.env.NEXT_PUBLIC_DISABLE_SENTRY !== 'false';

export const ikeaM2Header = process.env.X_IKEA_M2;

export const shouldEnableAllExperiments =
    process.env.NEXT_PUBLIC_ENABLE_ALL_EXPERIMENTS === 'true';

export const logLevel = process.env.LOG_LEVEL || 'info';

export const sentryDsn = process.env.SENTRY_DSN || '';
