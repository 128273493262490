import { createContext, PropsWithChildren, useMemo } from 'react';

import {
    createEcommerceEventHandler,
    createEventHandler,
    createLogEvent,
} from './helpers';
import useLocale from '@hooks/useLocale';
import { useSettings } from '@hooks/useSettings';
import { AnalyticsAvailability } from '@utils/analytics/eventTypes/ecommerce';

type AnalyticsContextType = {
    logEvents: ReturnType<typeof createEventHandler>;
    logEvent: ReturnType<typeof createLogEvent>;
    logEcommerceEvent: ReturnType<typeof createEcommerceEventHandler>;
    logUpdateQuantity: (
        items: {
            itemNo: string;
            quantityDelta: number;
            itemType: string;
            itemUnitPrice: number;
            availabilityOnline: AnalyticsAvailability;
            availabilityStore: AnalyticsAvailability;
            availabilityCollect: AnalyticsAvailability;
        }[],
        listId: string
    ) => void;
};

export const AnalyticsContext = createContext<AnalyticsContextType>({
    logEvents: () => undefined,
    logEvent: () => undefined,
    logEcommerceEvent: () => undefined,
    logUpdateQuantity: () => undefined,
});

export const AnalyticsProvider = ({ children }: PropsWithChildren) => {
    const { country, language } = useLocale();
    const { currency } = useSettings();
    const ctx = useMemo(() => {
        const logEvents = createEventHandler({ country, language, currency });
        const logEvent = createLogEvent(logEvents);
        const logEcommerceEvent = createEcommerceEventHandler({
            country,
            language,
        });

        const logUpdateQuantity = (
            items: {
                itemNo: string;
                quantityDelta: number;
                itemType: string;
                itemUnitPrice: number;
                availabilityOnline: AnalyticsAvailability;
                availabilityStore: AnalyticsAvailability;
                availabilityCollect: AnalyticsAvailability;
            }[],
            listId: string
        ) => {
            items.forEach(
                ({
                    quantityDelta,
                    itemNo,
                    itemType,
                    itemUnitPrice,
                    availabilityOnline,
                    availabilityStore,
                    availabilityCollect,
                }) => {
                    logEvent(
                        'ecommerce',
                        quantityDelta > 0
                            ? 'wishlist_increase_amount'
                            : 'wishlist_decrease_amount',
                        {
                            listId,
                            itemNo,
                            itemType,
                            quantityDelta,
                            itemUnitPrice,
                            availabilityOnline,
                            availabilityStore,
                            availabilityCollect,
                        }
                    );
                }
            );
        };

        return {
            logEvent,
            logEvents,
            logEcommerceEvent,
            logUpdateQuantity,
        };
    }, [country, language, currency]);

    return (
        <AnalyticsContext.Provider value={ctx}>
            {children}
        </AnalyticsContext.Provider>
    );
};
