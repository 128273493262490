import { getStorePostalCodesByMarket } from '@utils/StorePostalCodes';
import { GetMarketStructure, MarketName } from 'lib/global';

type MarketPrefix<T extends MarketName> = T extends 'usen' | 'uses'
    ? 'favorites'
    : 'favourites';

export type MarketData<T extends MarketName = MarketName> = {
    appPath: `/${GetMarketStructure<T>['country']}/${GetMarketStructure<T>['language']}/${MarketPrefix<T>}`;
    country: GetMarketStructure<T>['country'];
    ikeaMarketName: T;
    language: GetMarketStructure<T>['language'];
    locale: string;
    prefix: MarketPrefix<T>;
    postalCodes?: string[];
};

export const getMarketData = <T extends MarketName>(
    ikeaMarketName: T
): MarketData<T> => {
    const country = (ikeaMarketName[0] +
        ikeaMarketName[1]) as GetMarketStructure<T>['country'];
    const language = (ikeaMarketName[2] +
        ikeaMarketName[3]) as GetMarketStructure<T>['language'];
    const locale =
        country === 'no' && language === 'no'
            ? 'nb-NO'
            : `${language}-${country.toUpperCase()}`;
    const prefix = (
        country === 'us' ? 'favorites' : 'favourites'
    ) as MarketPrefix<T>;
    const postalCodes = getStorePostalCodesByMarket(country);
    return {
        appPath: `/${country}/${language}/${prefix}`,
        country,
        ikeaMarketName, // for good measure
        language,
        locale,
        prefix,
        postalCodes,
    };
};
