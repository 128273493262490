import React, { PropsWithChildren, useEffect } from 'react';

import BreadCrumbs from '@components/BreadCrumbs';
import ErrorBoundary from '@components/ErrorBoundary';
import { useStoreIntentPrompt } from '@components/Modals/StoreIntentPrompt/useStoreIntentPrompt';
import { useLogPrint } from '@utils/analytics/useLogPrint';
import { useOptimizely } from '@utils/optimizely/useOptimizely';
import useTranslations from 'context/Translations';
import type { AppProps } from 'next/app';
import Head from 'next/head';

import SessionInvalid from 'components/Modals/SessionInvalid';

const ComponentWrapper: React.FC<
    PropsWithChildren<Pick<AppProps, 'router'>>
> = ({ children, router }) => {
    useLogPrint();
    useStoreIntentPrompt();
    const translate = useTranslations();
    const { trackEvent } = useOptimizely();

    useEffect(() => {
        // Optimizely guardrail
        trackEvent('favourites_view');
    }, [trackEvent]);
    return (
        <>
            <Head>
                <title>{translate('SEO_PAGE_TITLE')}</title>
                <meta
                    name="description"
                    content={translate('LISTOVERVIEW_LISTS_CONTENT')}
                />
            </Head>
            <ErrorBoundary router={router}>
                <BreadCrumbs />
                {children}
            </ErrorBoundary>
            <SessionInvalid />
        </>
    );
};

export default ComponentWrapper;
