import { SettingsObject } from '@hooks/useSettings';

export const localhostDefaults: Partial<SettingsObject> = {
    kompassEnpoint: 'https://metro.ikeadt.net',
    listClientId: 'fe88ae57-2c34-4b14-8979-b915872886f7',
    listEndpoint: 'https://favs.oneweb.ingka.com/graphql',
    optimizelyBase: 'https://www.ppe.ikeadt.com',
    optimizelyKey: 'CwVNQ9rGUcVFbEJYcfZzv',
    profileClientId: '4d628482-8d07-4124-9010-3bad3fa622cd',
    profileEndpoint: 'https://api.ingka.ikea.com/api/profile/icm',
    showDevModal: true,
    sunEndpoint: 'https://api.ingka.ppe.ikeadt.com/sun/signup',
};
